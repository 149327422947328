.alert {
	font-size: $font-size-base;
}

.alert-icon {
	padding-left: 3rem;

	>i {
		color: inherit !important;
		font-size: 1rem;
		position: absolute;
		top: 1rem;
		left: 1rem;
	}
}

.alert-avatar {
	padding-left: 3.75rem;

	.avatar {
		position: absolute;
		top: .5rem;
		left: .75rem;
	}
}