.dataTables_wrapper{
  thead{
    .sorting{
      position: relative;
      &:after{
        position: absolute;
        right: 0;
        bottom: 5px;
        content: "\e92d";
        font-family: 'feather' !important;
      }
      &:before{
        position: absolute;
        right: 0;
        top: 5px;
        content: "\e930";
        font-family: 'feather' !important;
      }
    }
    .sorting_desc{
      position: relative;
      &:after{
        position: absolute;
        right: 0;
        bottom: 5px;
        content: "\e92d";
        font-family: 'feather' !important;
      }
    }
    .sorting_asc{
      position: relative;
      &:after{
        position: absolute;
        right: 0;
        top: 5px;
        content: "\e930";
        font-family: 'feather' !important;
      }
    }
  }
  .table{
    border-top: $card-border-width solid $card-border-color;
    border-bottom: $card-border-width solid $card-border-color;
  }
  .dataTables_length{
    margin: 1rem 1.5rem;
    float: left;
    select{
      @extend .form-control, .custom-select;
      width: auto;
      display: inline-block;
      margin: 0 0.2rem;
    }
  }
  .dataTables_filter{
    float: right;
    margin: 1rem 1.5rem;
    text-align: right;
    color: $body-color;
    input{
      @extend .form-control;
      width: auto;
      margin-left: 0.2rem;
      display: inline-block;
    }
  }
  
  .dataTables_paginate {
    float: right;
    text-align: right;
    margin: 1rem 1.5rem;
    .paginate_button{
      margin: 0 0.2rem;
      @extend .btn, .btn-secondary;
      &.current{
        @extend .btn, .btn-primary;
      }
    }
  }
  .dataTables_info {
    clear: both;
    float: left;    
    margin: 1rem 1.5rem;
    color: $body-color;
    line-height: 38px;
  }
}