.footer {
	background: $footer-bg;
	border-top: $border-width solid $border-color;
	font-size: px2rem(14px);
	padding: 1.25rem 0;
	color: $text-muted;

	a:not(.btn) {
		color: $text-muted-dark;
	}

	@media print {
		display: none;
	}
}