.pdf{
    white-space: nowrap;
    font-family: "Arial", Helvetica, sans-serif;
    color: #101010;
    width: 448px;
    margin: auto;
    overscroll-behavior: none;
    line-height: 1.8;
    font-size: .19rem;
    page {
        display: block;
        margin-bottom: 0.5cm;
        box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
    }
    .card-body{
        margin: 2rem 0.8rem 0 0.6rem;
        border: none;
    }
    .cust-footer-single {
        margin: 0 0.8rem 0 0.6rem;
        top: 570px;
        position: fixed;
    }
    .cust-footer-double {
        margin: 0 0.8rem 0 0.6rem;
        top: 1200px;
        position: fixed;
    }
    .tbl{
        font-family: "Calibri", Helvetica, sans-serif;
        margin-bottom: 0.3rem;
        width: 100%;
        tr {
            display: flex;
        }
        .comn-row{
            min-width: auto;
            padding: 0;
            border:#ff0;
        }
        th, td {
            flex-grow: 1;
            width: 100%;
            padding: 0px!important;
            margin: 0!important;
            text-indent: 0%;
            line-height: .36rem;

        }
    }
    table tbody{
        color:black;
        width: 100%;
    }
    .heading-pdf div{
        font-family: "Calibri", Helvetica, sans-serif;
        width: 33.33%;
        flex-grow: 1;
    }
    .total-heading {
        margin-left: 1px;
        font-family: "Calibri", Helvetica, sans-serif;
        margin-top: .375rem;
        width: auto;
        flex-shrink: 0;
        .prf-1 {
            padding: .0rem .5px!important;
            flex-grow: 1;
            justify-content: end;
            font-weight: bold;
            font-size: .2rem;
            line-height: .36rem;
        }
        .prf-val {
            padding: .0rem .5px!important;
            line-height: .36rem;
            flex-grow: 0;
            text-align: right;
        }
    }
    .table td, .table th, .table tr{
        vertical-align: unset;
        border-top: unset;
        color: black;
        padding: 0 !important;
        width: 100%;
    }
    .comn-row{
        min-width: 15px;
    }
    .line{
        border-bottom:0.01rem solid grey;
        height: 5px;
        width: 400px;
        margin-bottom: 1px;
    }
    .headings{
        font-weight: bold;
        height: .375rem;
    }
    .font-5{
        font-size: 5px;
    }
    .date-margin{
        margin-left: 15px;
        white-space: pre-wrap;      /* CSS3 */
        white-space: -moz-pre-wrap; /* Firefox */
        white-space: -pre-wrap;     /* Opera <7 */
        white-space: -o-pre-wrap;   /* Opera 7 */
        word-wrap: break-word;
        letter-spacing: 0.007rem;
        width : 400px;
        p {
            margin-bottom: 0px;
        }
    }
    .text-capitalize{
        text-transform: capitalize !important;
    }
    .mg-top-8{
        margin-top: 250px;
    }
    .mg-top-7{
        margin-top: 290px;
    }
    .footerText {
        width : 330px;
        white-space: pre-wrap;      /* CSS3 */
        white-space: -moz-pre-wrap; /* Firefox */
        white-space: -pre-wrap;     /* Opera <7 */
        white-space: -o-pre-wrap;   /* Opera 7 */
        word-wrap: break-word;
    }
}