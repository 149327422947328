@mixin form-btn {
    box-shadow: 0px 0px 10px #00000033;
    border-radius: 10px;
    font-family: $font-family;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.75px;
    font-weight: 800;
    text-transform: uppercase;
    color: white;
    width: 190px;
    height: 60px;
    margin-top: 14px;
    @media screen and (max-width:$large-one) {
        width: 180px;
        height: 50px;
    }
}

#saveas-modal {
    width: 500px;
    max-height: 800px;
    background: #252525 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    padding: 50px 87px !important;

    .modal-dialog{
        max-width: unset;
    }
    .modal-content{
        background: unset;
        border: unset;
        .close{
          padding: 0;
          background-color: transparent;
          border: 0;
          opacity: 1;
          color: $bg-red;
          text-shadow: none;
          &:focus{
              outline: unset;
              opacity: 1;
          }
        }
        .modal-title{
          font-family: "proxima-nova";
          font-size: 20px;
          line-height: 35px;
          letter-spacing: 1.25px;
          color: #FFFFFF;
          width: 100%;
        }
    }
      p{
        margin-top: 10px;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0px;
        margin-right: 1rem;
        font-weight: unset;
        color: white;
    }
  }

  #saveas-modal{
    .rotation-option{
        display: flex;
        label{
            @include login-home-formlabel;
            font-weight: 400;
        }
        margin-bottom: 30px !important;
    }
    .btn{
      width: 100px !important;
      height: 35px !important;
    }
    .checkbox-custom {
      zoom: 2;
      margin: 5px;
    }
    .check-height {
      height: 30px;
    }
  }

  .saveasModal {
    .modal-dialog{
        max-width: unset;
        margin: 0 auto;
        margin-left: 21%;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
  }