.aside {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: $aside-width;
    background: #ffffff;
    border-left: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    z-index: 100;
    visibility: hidden;
    box-shadow: 0 0 5px 2px rgba(#000, 0.05);

    body.aside-opened & {
        @media (min-width: 1600px) {
            visibility: visible;
        }
    }
}

.aside-body {
    padding: 1.5rem;
    flex: 1 1 auto;
    overflow: auto;
}

.aside-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid $border-color;
}

.aside-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $border-color;
}

/*Sidebar Styling Starts
===================================================*/

/*transition + animation
--------------------------------------*/

.page-wrapper,
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
.show-sidebar,
#close-sidebar {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

@keyframes swing {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(10deg);
    }
    30% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(0deg);
    }
    60% {
        transform: rotate(5deg);
    }
    70% {
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes sonar {
    0% {
        transform: scale(0.9);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

/* Toggle Sidebar
--------------------------------------*/

/*page-wrapper+sidebar
--------------------------------------*/

.page {
    .logo {
        width: 166px;
        overflow: hidden;
        min-width: 21px;
        img {
            width: 166px;
            min-width: 166px;
        }
        @include media(md) {
            width: 21px;
        }
        @include media(sm) {
            width: 166px;
        }
    }
    .page-wrapper {
        display: flex;
        flex-grow: 1;
    }
    &.toggled {
        .page-wrapper {
            @include media(sm) {
                padding-left: 0;
            }
            .logo {
                width: 21px;
                @include media(md) {
                    width: 120px;
                }
            }
        }

        .sidebar-wrapper {
            width: 70px;
            @include media(sm) {
                left: 0px;
            }
            @include media(md) {
                width: 260px;
            }
        }
        .toggle-sidebar {
            svg {
                path {
                    --offset: 57px;
                    &:nth-child(1),
                    &:nth-child(3) {
                        --delay: 0.15s;
                        --easing: cubic-bezier(0.2, 0.4, 0.2, 1.1);
                    }
                    &:nth-child(2) {
                        --duration: 0.4s;
                        --offset: 2px;
                        --array-1: 1px;
                    }
                    &:nth-child(3) {
                        --offset: 58px;
                    }
                }
            }
        }
        .box {
            width: 50px;
            @include media(md) {
                width: 260px;
            }
        }
        .sidebar-submenu a span {
            visibility: hidden;
        }
    }
    #open-sidebar {
        z-index: 999;
        transition: $transition-base;
    }
    .sidebar-wrapper {
        width: 260px;
        height: 100%;
        max-height: 100%;
        position: relative;
        left: 0;
        z-index: 999;
        transition: $transition-base;
        ul {
            list-style-type: none;
            padding: 0;
            transition: $transition-base;
            width: 300px;
            margin: 0;
        }
        li {
            border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        }
        a {
            text-decoration: none;
        }
        @include media(md) {
            width: 70px;
        }
        @include media(sm) {
            width: 0;
            height: calc(100vh - 70px);
        }
        @include media-breakpoint-up(md) {
            &:hover {
                width: 260px;
                .box {
                    width: 240px;
                }
                .logo {
                    width: 166px;
                }
            }
        }

        .offset-title {
            font-size: 14px;
            display: flex;
            font-weight: 500;
            cursor: pointer;
            width: 240px;
            transition: $transition-base;
            align-items: center;
            padding: 15px;
            color: rgba(255, 255, 255, 0.5);
            i {
                width: 20px;
                color: rgba(255, 255, 255, 0.5);
                font-size: 16px;
                text-align: center;
            }
            &:hover {
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }
    .sidebar-content {
        max-height: 100%;
        height: calc(100% - 70px);
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        &.desktop {
            overflow-y: hidden;
        }
        @include media(sm) {
            overflow: visible;
        }
    }
    &.show-sidebar {
        .sidebar-menu {
            left: 0px;
            transition-delay: 0.3s;
        }
    }
}

/*sidebar-menu
--------------------------------------*/

.box {
    transition: $transition-base;
    width: 240px;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.3);
    @include media(md) {
        width: 50px;
    }
}

.sidebar-menu {
    overflow: hidden;
    transition: $transition-base;
    .header-menu span {
        font-weight: bold;
        font-size: 14px;
        padding: 15px 20px 5px 15px;
        display: inline-block;
    }

    ul li {
        a {
            display: inline-block;
            width: 100%;
            text-decoration: none;
            position: relative;
            padding: 8px 30px 8px 20px;
        }

        &.disabled-item {
            pointer-events: none;
            opacity: 0.5;
        }

        a {
            i {
                margin-right: 20px;
                font-size: 13px;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                display: inline-block;
                border-radius: 4px;
            }

            &:hover > i::before {
                display: inline-block;
                animation: swing ease-in-out 0.5s 1 alternate;
            }
        }
    }

    .sidebar-dropdown {
        > a:after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f105";
            font-style: normal;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-align: center;
            background: 0 0;
            position: absolute;
            right: 15px;
            top: 14px;
        }

        .sidebar-submenu {
            height: 0px;
            overflow: hidden;
            transition: $transition-base;
            &.show {
                height: auto;
            }
            ul {
                padding: 5px 0;
                background-color: #021730;
            }

            li {
                border: none;
                padding-left: 0px;
                font-size: 13px;
                a {
                    padding: 10px 15px 10px 30px;
                }
                span {
                    visibility: visible;
                    transition: $transition-base;
                }
                a:before {
                    content: "\f111";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 400;
                    font-style: normal;
                    display: inline-block;
                    text-align: center;
                    text-decoration: none;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    margin-right: 25px;
                    font-size: 8px;
                }
            }
        }
    }

    ul li a span {
        &.label,
        &.badge {
            float: right;
            margin-top: 8px;
            margin-left: 5px;
        }
    }

    .sidebar-dropdown .sidebar-submenu li a {
        .badge,
        .label {
            float: right;
            margin-top: 0px;
        }
    }
    .sidebar-dropdown.active > a:after {
        transform: rotate(90deg);
        right: 17px;
    }
}

/*page-wrapper-theme
--------------------------------------*/

.page-wrapper {
    .sidebar-wrapper {
        .sidebar-brand {
            background: #183348;
            min-height: 70px;
            width: 260px;
            align-items: center;
        }
        .sidebar-brand > a,
        .sidebar-menu ul li a {
            color: #64696f;
        }
    }

    .sidebar-wrapper {
        .sidebar-menu {
            border-top: solid 1px rgba(255, 255, 255, 0.1);
            padding-top: 30px;
            ul li:hover > a,
            .active {
                color: #ddd;
                background-color: #2a2e33;
                cursor: pointer;
            }
        }

        .sidebar-header .user-info,
        .sidebar-brand > a:hover {
            color: #b8bfce;
        }
    }

    .sidebar-footer > a:hover i {
        color: #b8bfce;
    }
}

.page-wrapper.toggled .toggle-sidebar {
    color: #bdbdbd;

    &:hover {
        color: #ffffff;
    }
}

.sidebar-wrapper {
    ul li:hover a i,
    .sidebar-dropdown .sidebar-submenu li a:hover:before,
    .sidebar-search input.search-menu:focus + span {
        color: #d71920;
    }

    .sidebar-menu {
        a.active i {
            color: #d71920;
        }

        ul li a i {
            background: #1e2125;
        }
    }
}

.menu-trigger,
.menu-trigger span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
}
.menu-trigger {
    position: relative;
    width: 27px;
    height: 22px;
    &:focus {
        outline: none;
    }
}
.menu-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #8998a0;
    border-radius: 4px;
}
.menu-trigger span:nth-of-type(1) {
    top: 0;
    width: 14px;
}
.menu-trigger span:nth-of-type(2) {
    top: 9px;
    width: 22px;
}
.menu-trigger span:nth-of-type(3) {
    bottom: 0;
}

.toggled .menu-trigger span:nth-of-type(2) {
    background-color: #d63939;
}
