@each $color, $value in $colors {
    // @include bg-variant(".bg-#{$color}", $value);

    .text-#{$color} {
        color: $value !important;
    }
}

.icon {
    color: $text-muted !important;

    i {
        vertical-align: -1px;
    }

    @at-root a#{&} {
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: $body-color !important;
        }
    }
}

.o-auto {
    overflow: auto !important;
}
.o-hidden {
    overflow: hidden !important;
}

.shadow {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}
.shadow-none {
    box-shadow: none !important;
}

.bg-dark-overlay {
    color: $white;
    background-color: $light-black;
}

.bg-white-overlay {
    color: $white;
    background-color: $light-white;
}

.rounded-md {
    border-radius: 50px;
}
