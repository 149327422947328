.list-group-item {
	&.active {
		.icon {
			color: inherit !important;
		}
	}
}

.list-group-transparent {
	.list-group-item {
		background: none;
		border: 0;
		padding: .5rem 1rem;
		border-radius: 3px;

		&.active {
			background: rgba($primary, .06);
			font-weight: 600;
		}
	}
}