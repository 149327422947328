.toggled {
    .header {
        @include media(sm) {
            left: 0;
            // background-color: #082a55;
        }
    }
}

.header {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background: #fff;
    transition: $transition-base;
    left: 0px;
    min-height: 70px;
    z-index: 9;
    border-bottom: 1px solid $border-color;

    body.fixed-header & {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: $zindex-fixed;
    }

    @media print {
        display: none;
    }

    .dropdown-menu {
        margin-top: 0.75rem;
    }
    @include media(md) {
        left: 0px;
    }
    @include media(sm) {
        left: 0;
        min-height: 70px;
        // background: #082a55;
    }
}

.nav-unread {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    background: $red;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
}

.header-brand {
    color: inherit;
    margin-right: 1rem;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: 0.3s opacity;
    line-height: 2rem;

    &:hover {
        opacity: 0.8;
        color: inherit;
        text-decoration: none;
    }
}

.header-brand-img {
    height: 2rem;
    line-height: 2rem;
    vertical-align: bottom;
    margin-right: 0.5rem;
    width: auto;
}

.header-avatar {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    vertical-align: bottom;
    border-radius: 50%;
}

//.header-nav {
//	background: #fff;
//	border-bottom: 1px solid $border-color;
//	color: $text-muted;
//	align-items: center;
//
//	@media print {
//		display: none;
//	}
//
//	.nav-tabs {
//		border: 0;
//	}
//}

.header-btn {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: 1rem;

    &.has-new {
        position: relative;

        &:before {
            content: "";
            width: 6px;
            height: 6px;
            background: $red;
            position: absolute;
            top: 4px;
            right: 4px;
            border-radius: 50%;
        }
    }
}

.header-toggler {
    width: 2rem;
    height: 2rem;
    position: relative;
    color: $text-muted;

    &:hover {
        color: $text-muted-dark;
    }
}

.header-toggler-icon {
    position: absolute;
    width: 1rem;
    height: 2px;
    color: inherit;
    background: currentColor;
    border-radius: 3px;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -0.5rem;
    box-shadow: 0 5px currentColor, 0 -5px currentColor;
}
