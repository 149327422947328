//FONTS

@mixin clearfix {
    &::after {
        clear: both;
        content: "";
        display: block;
    }
}

@mixin placeholder-text($color) {
    &::-webkit-input-placeholder {
        color: $color;
        opacity: 1;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: $color;
        opacity: 1;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: $color;
        opacity: 1;
    }
}

$grid-bp: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
) !default;

@mixin media($breakpoint) {
    @if $breakpoint == xs {
        @media (max-width: map-get($grid-bp , sm) - 1px) {
            @content;
        }
    }
    @if $breakpoint == sm {
        @media (max-width: map-get($grid-bp , md) - 1px) {
            @content;
        }
    }
    @if $breakpoint == md {
        @media (max-width: map-get($grid-bp , lg) - 1px) {
            @content;
        }
    }
    @if $breakpoint == lg {
        @media (max-width: map-get($grid-bp , xl) - 1px) {
            @content;
        }
    }
    @if $breakpoint == xl {
        @media (max-width: map-get($grid-bp , xxl) - 1px) {
            @content;
        }
    }
}

@mixin media_min($breakpoint) {
    @if $breakpoint == sm {
        @media (min-width: map-get($grid-breakpoints , sm)) {
            @content;
        }
    }
    @if $breakpoint == md {
        @media (min-width: map-get($grid-breakpoints , md)) {
            @content;
        }
    }
    @if $breakpoint == lg {
        @media (min-width: map-get($grid-breakpoints , lg)) {
            @content;
        }
    }
}

@mixin border-radius($r) {
    -webkit-border-radius: $r;
    border-radius: $r;
}

@mixin gradient($start, $end) {
    background: $start;
    background: -moz-linear-gradient(top, $start 0%, $end 100%);
    background: -webkit-linear-gradient(top, $start 0%, $end 100%);
    background: linear-gradient(to bottom, $start 0%, $end 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start', endColorstr='$end',GradientType=0 );
}

@mixin bg-variant($parent, $color, $ignore-warning: true) {
    #{$parent} {
        background-color: $color !important;
    }
    a#{$parent},
    button#{$parent} {
        @include hover-focus() {
            background-color: darken($color, 10%) !important;
        }
    }
    @include deprecate("The `bg-variant` mixin", "v4.4.0", "v5", $ignore-warning);
}

@mixin text-variant($parent, $color, $ignore-warning: true) {
    #{$parent} {
        color: $color !important;
    }
    a#{$parent},
    button#{$parent} {
        @include hover-focus() {
            color: darken($color, 10%) !important;
        }
    }
    @include deprecate("The `bg-variant` mixin", "v4.4.0", "v5", $ignore-warning);
}

@mixin dark-mode {
    @if $enable-dark-mode {
        $selector: &;

        @media not print {
            @if ($selector) {
                .theme-dark & {
                    @content;
                }
            } @else {
                .theme-dark {
                    @content;
                }
            }
        }

        @media not print and (prefers-color-scheme: dark) {
            @if ($selector) {
                .theme-dark-auto & {
                    @content;
                }
            } @else {
                .theme-dark-auto {
                    @content;
                }
            }
        }
    }
}
