@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap");

// Variables
@import "variables";

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

// Whelen UI
@import "dashboard/dashboard";

@import "darkmode";

// Whelen pages
@import "pages/general";

// change password
@import "pages/change_password";

// pdf
@import "pages/pdf";

// Confirmation Modal
@import "pages/confirm_modal";

// Session timeout Modal
@import "pages/session_modal";

// Changes COnfirmation Modal
@import "pages/changes_warning_modal";


// Font Icon
@import "../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import url("https://use.typekit.net/apm0zsy.css");
