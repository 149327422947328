$font-family: "proxima-nova";
$bg-red:#E72729;

#confirm-modal {
    width: 500px;
    max-height: 800px;
    background: #252525 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    padding: 50px 50px !important;

    .modal-dialog{
        max-width: unset;
    }
    .modal-content{
        background: unset;
        border: unset;
        .close{
          padding: 0;
          background-color: transparent;
          border: 0;
          opacity: 1;
          color: $bg-red;
          text-shadow: none;
          &:focus{
              outline: unset;
              opacity: 1;
          }
        }
        .modal-title{
          margin: 0;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 2.5rem;
        }
    }
  }

  #confirm-modal{
    .rotation-option{
        display: flex;
        label{
            @include login-home-formlabel;
            font-weight: 400;
        }
        margin-bottom: 30px !important;
    }
    .btn{
      width: 100px !important;
      height: 35px !important;
    }
  }

  .confirmModal {
    .modal-dialog{
        max-width: unset;
        margin: 0 auto;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
  }